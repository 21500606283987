<template lang="pug">
div.list-box
  van-pull-refresh(
    v-model="refreshing",
    @refresh="onRefresh"
  )
    van-list(
      v-model="loading",
      :finished="finished",
      :finished-text="finishedText",
      @load="onLoadList",
      style="min-height: calc(100vh); overflow: hidden;"
    )
      ReportList(
        :listdata="list",
        @updateList="updateList"
      )
</template>
<script>
import ReportList from '@/components/ReportList/index'
import { dataIndex } from '@/config/apis.js'
import { Toast } from 'vant'
export default {
  components: {
    ReportList
  },
  data () {
    return {
      loading: false,
      refreshing: false,
      finished: false,
      finishedText: '加载中...',
      pageNo: -1,
      pageSize: 8,
      list: []
    }
  },
  computed: {
    phone () {
      return this.$route.query.phone
    }
  },
  methods: {
    filterUniqueData (data) {
      const map = new Map()
      const uniqueData = []
      for (const item of data) {
        if (!map.has(item.id)) {
          map.set(item.id, true)
          uniqueData.push(item)
        }
      }
      return uniqueData
    },
    getComplaintList () {
      if (!this.phone) {
        Toast.fail('手机号异常,请稍后再试')
        return
      }
      if (this.refreshing) {
        this.refreshing = false
        this.list = []
        this.pageNo = 0
        this.finishedText = '加载中...'
      }
      const data = {
        offset: this.pageNo * this.pageSize,
        limit: this.pageSize,
        complaint_phone: this.phone
      }
      dataIndex(data).then((res) => {
        if (res.code === 1) {
          // 没有数据
          if (res.data.total === 0) {
            this.finished = true
            this.finishedText = '暂无数据'
            return
          }
          // 获取的数据小于limit
          if (res.data.rows.length < this.pageSize) {
            this.finished = true
            this.finishedText = '下面没有了'
          }
          this.list = this.filterUniqueData(this.list.concat(res.data.rows))
          this.loading = false
        }
      }).catch(e => {
        Toast.fail(e.msg)
      })
    },
    updateList () {
      this.refreshing = true
      this.getComplaintList()
    },
    onLoadList () {
      this.loading = true
      this.pageNo++
      this.getComplaintList()
    },
    onRefresh () {
      // 清空列表数据
      this.finished = false
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true
      this.getComplaintList()
    }
  }
}
</script>
<style lang="scss" scoped>
.list-box {
  background-color: #f6f6f7;
}
</style>
