<template lang="pug">
div
  div.index(
    v-for="(item,index) in listdata",
    :key="index"
  )
    div.head(
      :class="{'head-pending': item.state === 'pending','head-processed': item.state === 'processed','head-cancel': item.state === 'cancel','head-handle': item.state === 'handle'}"
    )
      div.type
        .title {{item.complaint_kind_one_text}} {{item.complaint_kind_two_text}}
        .time {{item.create_time}}
      div.status
        van-tag(
          v-if="item.is_anonymous ==='1'"
          size="large"
          color="#e6e6e68a"
          text-color="#000"
          style="margin-right: 5px"
        ) 匿
        //- 待处理
        van-tag(
          v-if="item.state==='pending'"
          size="large"
          color="#ffe1e18a"
          text-color="#ad0000"
        ) {{item.state_text}}
        //- 处理中
        van-tag(
          v-if="item.state==='processed'"
          size="large"
          color="#fee6aa8a"
          text-color="#f68300"
        ) {{item.state_text}}
        //- 已撤销
        van-tag(
          v-if="item.state==='cancel'"
          size="large"
          color="#e6e6e68a"
          text-color="#9e9898"
        ) 已撤销
        //- 已处理
        van-tag(
          v-if="item.state==='handle'"
          size="large"
          color="#90e6a18a"
          text-color="#0e6900"
        ) {{item.state_text}}
    div.notice-index
      //- div.item-name
      //-   div
      //-     span.title 举报时间
      //-     span.content {{item.create_time}}
      div.name(
        v-if="item.is_anonymous !=='1' "
      )
        div.item
          span.title 姓名:
          span.content {{item.complainant_name}}
        div.item
          span.title 性别：
          span.content {{item.complainant_sex}}
      div.item
        span.title 手机号：
        span.content {{item.complaint_phone}}
      //- div.item
      //-   span.title 举报问题所在地 ：
      //-   span.content {{ item.county_name }}
      div.item
        span.title 位置信息：
        span.content {{item.location}}
      div.item
        span.title 举报情况：
        span.content {{item.report_situation}}
      div.item
        span.title 办理诉求：
        span.content {{item.appeal}}
      div.images-title(v-if="item.attach.length") 附件：
      div.images-box
        van-image.image-item(
          v-for="(itemson,index) in item.attach",
          :key="index",
          @click="clickImage(item.attach,index)",
          width="50",
          height="50",
          :src="itemson.url"
        )
      //- div.item(
      //-   style="margin-top:2px;",
      //-   v-if="item.content"
      //- )
      //-   span.title 答复内容 ：
      //-   span.content {{item.content}}
      div.item(
        style="margin-top:2px;",
        v-if="item.reply_content"
      )
        span.title 处理结果：
        span.content {{item.reply_content}}
      div.btn
        van-button(
          v-if="item.is_cancel===1"
          plain
          size="small"
          icon="revoke"
          @click="revoke(item.id)"
        ) 撤销
</template>
<script>
import { ImagePreview, Dialog, Toast } from 'vant'
import { cancelComplaint } from '@/config/apis.js'
export default {
  name: 'ReportList',
  props: ['listdata'],
  methods: {
    clickImage (arr, index) {
      const images = arr.map(item => item.url)
      ImagePreview({
        images: images,
        showIndex: true,
        loop: false,
        startPosition: index
      })
    },
    revoke (id) {
      console.log(id)
      const that = this
      Dialog.confirm({
        messageAlign: 'left',
        message: '您确认要撤销此条举报信息吗？'
      })
        .then(() => {
          const data = { id }
          cancelComplaint(data).then(res => {
            if (res.code === 1) {
              Toast.success('操作成功')
              that.$emit('updateList')
            } else {
              Toast.fail(res.msg)
            }
          }).catch(e => {
            Toast.fail('服务器繁忙,稍后再试')
          })
        })
        .catch(() => {
          // on cancel
        })
    }
  }
}
</script>
<style lang="scss" scoped>
.index {
  margin: 0.2rem;
  background-color: #ffffff;
  border-radius: 6px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
}
.head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 56px;
  padding: 0 10px;
  .type {
    .title {
      color: #020202;
      font-weight: 800;
      font-size: 16px;
    }
    .time {
      font-size: 12px;
      color: #666666;
    }
  }
}
.head-pending{
  background: linear-gradient(to right, #fdf4f2cc, white);
}
.head-processed{
  background: linear-gradient(to right, #fff8e782, white);
}
.head-cancel{
  background: linear-gradient(to right, #ecececb8, white);
}
.head-handle{
  background: linear-gradient(to right, #eefff1ab, white);
}
.notice-index {

  padding: 0.1rem 0.2rem 0.2rem;
  // border-radius: 10px;
  position: relative;

  .item {
    line-height: 32px;
    font-size: 14px;
    .title {
      // font-size: 1px;
      color: #333333;
      // font-weight: 500;
      width: 72px;
      display: inline-block;
    }
    .content {
      color: #333333;
      // font-weight: 500;
    }
  }
  .name {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title {
      width: 68px;
    }
  }
  .item-name {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @extend .item;
  }
  .images-title {
    font-size: 13px;
    color: #000000;
    font-weight: 500;
    margin-bottom: 0.1rem;
    line-height: 20px;
  }
  .images-box {
    display: flex;
    align-items: center;
    .image-item {
      margin-right: 5px;
    }
  }
}
.btn{
  display: flex;
  justify-content: end;
}
</style>
